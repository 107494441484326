import { handleRedirectionWithSignupSource } from '@common/utils'
import { Trans } from '@lingui/macro'
import Router from 'next/router'

const FixedBookAClassNowButton = () => (
  <div className="fixed bottom-0 left-0 right-0 z-20 py-2 text-center md:hidden bg-grey-100">
    <button
      onClick={() =>
        handleRedirectionWithSignupSource(Router.asPath, '/register')
      }
      className="w-11/12 text-xl btn btn-orange font-600"
    >
      <Trans>Try a free lesson</Trans>
    </button>
  </div>
)

export default FixedBookAClassNowButton
