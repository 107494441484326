import { ROUTES } from '@common/enums'
import { handleRedirectionWithSignupSource } from '@common/utils'
import { FastlyImage } from '@components/common/image'
import useResolution from '@hooks/useResolution'
import { Trans } from '@lingui/macro'
import { useRouter } from 'next/router'

const { REGISTER } = ROUTES

type Props = {
  bannerContent?: string
  localeCountryCode?: string
}
const MagicalCodingWorldBanner = ({
  localeCountryCode,
  bannerContent,
}: Props) => {
  const router = useRouter()
  const { sizes } = useResolution()

  const BannerImage = () => (
    <>
      {router?.asPath === '/coding-for-kids/' && (
        <div className="lg:-mt-16">
          <FastlyImage
            alt="kids enjoying to learn code"
            src="/images/coding-for-kids/book-free-class-banner.png"
            webpSrc="/images/coding-for-kids/book-free-class-banner.webp"
            height={400}
            width={620}
          />
        </div>
      )}
      {router?.asPath === '/national-coding-olympiad-2021/' && (
        <FastlyImage
          alt="kids enjoying to learn code"
          src="/images/national-coding-olympiad/banner.png"
          webpSrc="/images/national-coding-olympiad/banner.webp"
          height={430}
          width={620}
        />
      )}
      {localeCountryCode === 'US' && (
        <div style={!sizes.sm ? { marginTop: '-43px' } : null}>
          <FastlyImage
            alt="kids enjoying to learn code"
            src="/images/home/book-free-class-banner/book-free-class-banner-us.png"
            webpSrc="/images/home/book-free-class-banner/book-free-class-banner-us.webp"
            height={400}
            width={600}
          />
        </div>
      )}
      {!localeCountryCode &&
        router?.asPath !== '/coding-for-kids/' &&
        router?.asPath !== '/national-coding-olympiad-2021/' && (
          <div className="lg:-mt-16">
            <FastlyImage
              alt="coding course for kids"
              src="/images/home/book-free-class-banner/students-with-laptop.png"
              webpSrc="/images/home/book-free-class-banner/students-with-laptop.webp"
              height={400}
              width={400}
            />
          </div>
        )}
    </>
  )

  return (
    <div className="relative flex flex-col max-w-full px-10 mx-3 -mb-10 bg-orange-600 rounded-md lg:px-20 lg:flex-row lg:mx-auto lg:max-w-5xl h-full lg:h-48">
      <div className="flex flex-col justify-center w-full my-6 space-y-4 text-center text-white lg:text-left">
        <div className="text-3xl leading-tight font-700">
          {bannerContent || (
            <Trans>
              Take your first step into the
              <br /> magical world of coding
            </Trans>
          )}
        </div>
        <button
          className="px-10 py-3 text-lg bg-white border-none lg:mr-auto btn text-orange shadow-md"
          onClick={() =>
            handleRedirectionWithSignupSource(router.asPath, REGISTER)
          }
        >
          <Trans>Try a free lesson</Trans>
        </button>
      </div>
      <BannerImage />
    </div>
  )
}

export default MagicalCodingWorldBanner
