import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { msgT } from '@lib/utils/i18n'
import { NextSeo, NextSeoProps } from 'next-seo'

type Props =
  | NextSeoProps
  | {
      title?: string | LinguiJSMessageDescriptor
      description?: string | LinguiJSMessageDescriptor
      openGraph?: {
        type: string
        title: string | LinguiJSMessageDescriptor
        description: string | LinguiJSMessageDescriptor
        url: string
        ['site_name']: string
        images: {
          url: string
          alt: string
        }[]
      }
      twitter: {
        handle: string
        cardType: string
      }
      facebook: {
        appId: string
      }
    }

const I18nNextSeo = (props: Props) => {
  return (
    <NextSeo
      {...props}
      title={msgT(props?.title)}
      description={msgT(props?.description)}
      openGraph={{
        ...props?.openGraph,
        title: msgT(props?.openGraph.title),
        description: msgT(props?.openGraph?.description),
      }}
    />
  )
}

export default I18nNextSeo
