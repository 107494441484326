import BookFreeClassBtn from '@components/common/book-free-class-btn'
import MsgTrans from '@components/common/i18n/message-trans'
import { FastlyImage } from '@components/common/image'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { defineMessage, t, Trans } from '@lingui/macro'

const IMAGE_PATH = '/images/home/reasons/'

type Image = {
  path: string
  sizes: {
    height: number
    width: number
  }
  alt: LinguiJSMessageDescriptor
}
type Reasons = {
  heading: LinguiJSMessageDescriptor
  content: LinguiJSMessageDescriptor
  image: Record<string, Image>
}

type ReasonCardType = Reasons & {
  isImageOnRight?: boolean
  localeCountryCode?: string
}

const REASONS: Reasons[] = [
  {
    heading: defineMessage({ message: 'Develop key skills' }),
    content: defineMessage({
      message: `Learning to code helps improve a child's logical thinking and problem-solving skills by 70%, also enabling them to perform better at school.`,
    }),
    image: {
      PK: {
        path: `${IMAGE_PATH}pk/picture1.png`,
        sizes: {
          height: 280,
          width: 400,
        },
        alt: defineMessage({
          message: `Children are full of ideas and coding helps children develop key creative skills`,
        }),
      },

      default: {
        path: `${IMAGE_PATH}/reason-1.png`,
        sizes: {
          height: 300,
          width: 300,
        },
        alt: defineMessage({
          message: `Children are full of ideas and coding helps children develop key creative skills`,
        }),
      },
    },
  },
  {
    heading: defineMessage({ message: 'Prepare for the future' }),
    content: defineMessage({
      message: `Children need to learn coding and develop digital skills if they want to be successful in the technology-driven job market of the future.`,
    }),
    image: {
      PK: {
        path: `${IMAGE_PATH}pk/picture2.png`,
        sizes: {
          height: 280,
          width: 400,
        },
        alt: defineMessage({
          message: `A child learn to code in front of a computer and prepares for a bright future`,
        }),
      },
      default: {
        path: `${IMAGE_PATH}/reason-2.png`,
        sizes: {
          height: 300,
          width: 300,
        },
        alt: defineMessage({
          message: `A child learn to code in front of a computer and prepares for a bright future`,
        }),
      },
    },
  },
  {
    heading: defineMessage({ message: 'Learn through coding' }),
    content: defineMessage({
      message: `Our STEM-focused curriculum makes coding a learning aid and helps kids develop a deeper understanding of the subjects they study at school.`,
    }),
    image: {
      PK: {
        path: `${IMAGE_PATH}pk/picture3.png`,
        sizes: {
          height: 280,
          width: 400,
        },
        alt: defineMessage({
          message: `A child plays with educational toys and learns new things`,
        }),
      },
      default: {
        path: `${IMAGE_PATH}/reason-3.png`,
        sizes: {
          height: 300,
          width: 300,
        },
        alt: defineMessage({
          message: `A child plays with educational toys and learns new things`,
        }),
      },
    },
  },
]

const ReasonCard = ({
  heading,
  content,
  image,
  isImageOnRight = false,
  localeCountryCode,
}: ReasonCardType) => {
  const imageProps = image?.[localeCountryCode] || image?.default

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 text-center lg:text-auto text-grey lg:max-w-4xl lg:mx-auto">
      {!isImageOnRight && (
        <>
          <div className="block lg:hidden">
            <FastlyImage
              src={imageProps.path}
              alt={t({ id: imageProps.alt?.id })}
              height={imageProps.sizes.height}
              width={imageProps.sizes.width}
            />
          </div>

          <div className="flex flex-col justify-center text-center lg:text-left">
            <h3 className="py-4 text-2xl font-600 lg:text-3xl">
              <MsgTrans msg={heading} />
            </h3>
            <p className="text-base lg:text-xl">
              <MsgTrans msg={content} />
            </p>
          </div>

          <div className="hidden text-right lg:block">
            <FastlyImage
              src={imageProps.path}
              alt={t({ id: imageProps.alt?.id })}
              height={imageProps.sizes.height}
              width={imageProps.sizes.width}
            />
          </div>
        </>
      )}

      {isImageOnRight && (
        <>
          <div>
            <FastlyImage
              src={imageProps.path}
              alt={t({ id: imageProps.alt?.id })}
              height={imageProps.sizes.height}
              width={imageProps.sizes.width}
            />
          </div>

          <div className="flex flex-col justify-center text-center lg:text-left">
            <div className="py-4 text-2xl font-600 lg:text-3xl">
              <MsgTrans msg={heading} />
            </div>
            <p className="text-base lg:text-xl">
              <MsgTrans msg={content} />
            </p>
          </div>
        </>
      )}
    </div>
  )
}

type Props = {
  localeCountryCode?: string
}

/** i18n support added */
const WhyLearnToCode = ({ localeCountryCode }: Props) => {
  return (
    <div className="flex flex-col w-full px-5 md:px-3 py-10 mx-auto space-y-10 lg:py-16 lg:space-y-20 lg:max-w-6xl text-grey leading-tight">
      <h3 className="text-2xl text-center lg:text-4xl font-600">
        <Trans id="homepage.whyLearnCoding.heading">
          Why children should{' '}
          <span className="yellow-underline">learn to code</span>
        </Trans>
      </h3>
      <div className="flex flex-col space-y-20">
        {REASONS.map((reason, index) => (
          <ReasonCard
            key={reason.content?.id}
            heading={reason.heading}
            content={reason.content}
            image={reason.image}
            isImageOnRight={index % 2 === 0}
            localeCountryCode={localeCountryCode}
          />
        ))}
      </div>

      <div className="mx-auto px-5 md:px-0 w-full lg:w-auto lg:px-0">
        <BookFreeClassBtn size="xl" />
      </div>
    </div>
  )
}

export default WhyLearnToCode
