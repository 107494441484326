import { CODINGAL_COMMUNITY_STRENGTH } from '@common/constants'
import BookFreeClassButton from '@components/common/book-free-class-btn'
import { FastlyImage } from '@components/common/image'
import { Trans } from '@lingui/macro'

const IMAGE_PATH = '/images/home/trusted-platform/'
const COMMON_IMAGE_PATH = '/images/common/'

const FeatureCardWrapper = ({ children }) => (
  <div className="z-10 w-full text-center bg-white rounded shadow">
    <div className="py-8 px-10 text-2xl leading-tight text-grey-900 text-left">
      {children}
    </div>
  </div>
)

const TrustedPlatform = () => {
  return (
    <div className="container mx-auto relative flex flex-col items-center px-5 space-y-16 py-16 text-grey">
      <h2 className="text-2xl lg:text-4xl leading-tight text-center font-600">
        <Trans id="homepage.trustedPlatform.heading">
          <span className="yellow-underline">
            Most loved & trusted platform
          </span>{' '}
          for kids and teens to learn coding basics and fundamentals
        </Trans>
      </h2>
      <div className="relative grid lg:grid-cols-3 justify-center gap-5 lg:mx-auto">
        <FeatureCardWrapper>
          <div>
            <FastlyImage
              src={`${IMAGE_PATH}satisfaction.png`}
              alt="vector shape"
              height={50}
              width={50}
            />
          </div>
          <Trans>
            Parent satisfaction score of{' '}
            <span className="text-blue-200">4.8</span> in{' '}
            <span className="text-blue-200">5</span>
          </Trans>
        </FeatureCardWrapper>

        <FeatureCardWrapper>
          <div>
            <FastlyImage
              src={`${IMAGE_PATH}review.png`}
              alt="vector shape"
              height={50}
              width={50}
            />
          </div>
          <Trans>
            Cumulative teacher review score of{' '}
            <span className="text-blue-200">4.9</span> in{' '}
            <span className="text-blue-200">5</span>
          </Trans>
        </FeatureCardWrapper>

        <FeatureCardWrapper>
          <div>
            <FastlyImage
              src={`${IMAGE_PATH}students.png`}
              alt="vector shape"
              height={50}
              width={45}
            />
          </div>
          <Trans>
            <span className="text-blue-200">
              {CODINGAL_COMMUNITY_STRENGTH}+
            </span>{' '}
            students signed up till now
          </Trans>
        </FeatureCardWrapper>

        <FeatureCardWrapper>
          <div>
            <FastlyImage
              src={`${COMMON_IMAGE_PATH}/partners/trust-pilot.png`}
              alt="vector shape"
              height={60}
              width={120}
            />
          </div>
          <Trans>
            Students and Parents review score of{' '}
            <span className="text-blue-200">4.7</span> in{' '}
            <span className="text-blue-200">5</span>
          </Trans>
        </FeatureCardWrapper>

        <FeatureCardWrapper>
          <div>
            <FastlyImage
              src={`${IMAGE_PATH}dashboard.png`}
              alt="vector shape"
              height={50}
              width={70}
            />
          </div>
          <Trans>Dashboard to track learning milestones and progress</Trans>
        </FeatureCardWrapper>

        <FeatureCardWrapper>
          <div>
            <FastlyImage
              src={`${IMAGE_PATH}guide.png`}
              alt="vector shape"
              height={50}
              width={50}
            />
          </div>
          <Trans>Guided course journey for each child</Trans>
        </FeatureCardWrapper>
      </div>

      <div className="flex flex-col justify-center w-full mt-10 gap-y-4 lg:mx-0 lg:flex-row lg:gap-x-1 pb-5">
        <BookFreeClassButton size="xl" />
      </div>
    </div>
  )
}

export default TrustedPlatform
